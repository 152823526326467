<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Imagem -->
          <validation-provider
            v-slot="{ errors }"
            name="thumb"
            rules="required"
          >
            <b-form-group
              label="Capa da live  "
              label-for="thumb"
            >
              <b-form-file
                id="thumb"
                v-model="form.thumb"
                placeholder="Selecione a capa..."
                drop-placeholder="Arraste seu arquivo para aqui..."
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Titulo -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Tipo do Link -->
          <validation-provider
            v-slot="{ errors }"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Tipo do link"
              label-for="type"
            >
              <v-select
                id="type"
                v-model="form.type"
                :options="type_link"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Link -->
          <validation-provider
            v-slot="{ errors }"
            name="url"
            rules="required"
          >
            <b-form-group
              label="url"
              label-for="url"
            >
              <b-form-input
                id="url"
                v-model="form.url"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- DAta -->
          <validation-provider
            v-slot="{ errors }"
            name="date"
            rules="required"
          >
            <b-form-group
              label="Data"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                v-model="form.date"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- inicio do tempo -->
          <validation-provider
            v-slot="{ errors }"
            name="start_time"
            rules="required"
          >
            <b-form-group
              label="Inicio"
              label-for="start_time"
            >
              <b-form-timepicker
                v-model="form.start_time"
                locale="pt-br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM Required -->
          <validation-provider
            v-slot="{ errors }"
            name="crm_required"
            rules="required"
          >
            <b-form-group
              label="CRM OBRIGATORIO"
              label-for="crm_required"
            >
              <v-select
                id="crm_required "
                v-model="form.crm"
                :options="boolean"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Ready -->
          <validation-provider
            v-slot="{ errors }"
            name="ready"
            rules="required"
          >
            <b-form-group
              label="Pronto para iniciar"
              label-for="ready"
            >
              <v-select
                id="ready "
                v-model="form.ready"
                :options="boolean"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip, BFormTextarea
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import { required, alphaNum, email } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import store from '@/store'
// eslint-disable-next-line import/no-unresolved
// import countries from '@/@fake-db/data/other/countries'
// import { mapActions } from 'vuex'
import { integer } from 'vee-validate/dist/rules'

// service import
import LivesServices from './LivesServices'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countries,
      speaker_id: null,
      event_id: null,
      boolean: ['Não', 'Sim'],
      typeEvent: ['Palestra', 'Mesa redonda'],
      level: ['Facil', 'Intermediario', 'Avançado'],
      language: ['Portugues', 'Ingles'],
      room: ['Sala 01', 'Sala 02'],
      type_link: ['Youtube', 'Cloudflare', 'Vimeo'],
      events: [],
      speakers: [],
      form: {}
    }
  },
  methods: {
    resetForm() {
      this.form = {}
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            // form data is needed because image file
            const formData = new FormData()
            const imagefile = document.querySelector('#thumb')
            formData.append('img', imagefile.files[0])
            formData.append('title', this.form.title)
            formData.append('type', this.form.type)
            formData.append('url', this.form.url)
            formData.append('date', this.form.date)
            formData.append('time', this.form.start_time)
            formData.append('crm_required', this.form.crm)
            formData.append('ready', this.form.ready)
            // formData.append('...', this.form...)

            // Call to create request
            const response = await LivesServices().liveCreate(formData)

            if (response.data.status === 400) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: response.data.message,
                  text: ` ERROR: ${response.data.errors[0][0]} #${response.data.errors[0][1]}`,
                  variant: 'danger',
                }
              })
            } else if (response.data.status === 200) {
              LivesServices().getLives().then(api => {
                this.lives = api.data.response.lives
                this.lives.forEach((live, index) => {
                  if (live.crm_required === 0) {
                    live.crm_required = 'Obrigatorio'
                  } else {
                    live.crm_required = 'Livre'
                  }
                })
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'InfoIcon',
                  variant: 'success',
                }
              })
              this.$emit('close')
              document.location.reload(true)
            }
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
